import styled, { css } from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import HexToRGB from '../HexToRGB';
import ResponsiveMediaQuery from '../ResponsiveMediaQuery';
import { AppTheme } from '../Theme';

export type ButtonProps = HelperProps & {
  variant?: 'dark' | 'colored' | 'secondary' | 'white' | 'allWhite' | 'whiteOnPrimary'; // White == default
  color?: keyof AppTheme['colors'];
  fullWidth?: boolean;
  fontSize?: string;
  fontWeight?: string;
  noHoverEffect?: boolean;
};

const defaultButton = (color: ButtonProps['color'], props: ButtonProps) => css`
  background-color: ${(props) => (color ? props.theme.colors[color] : props.theme.colors.primary)};
  color: ${(props) => props.theme.colors.paragraphOnPrimary};
  border: none;
  transition: filter 130ms ease-in-out 0s;
  border-radius: 4px;
  width: 100%;
  max-width: 280px;

  ${ResponsiveMediaQuery('tablet')} {
    width: auto;
    max-width: inherit;
  }

  &:hover {
    ${props.noHoverEffect ? '' : 'filter: brightness(0.85);'}
  }

  &:active {
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const outlineButton = (color: string) => css`
  background-color: transparent;
  color: ${color};
  border: 2px solid ${color};
  transition: filter 130ms ease-in-out 0s;

  &:hover {
    background-color: ${`rgba(${HexToRGB(color)}, 0.1)`};
  }

  &:active {
    background-color: ${`rgba(${HexToRGB(color)}, 0.15)`};
    transition: none;
  }
`;

const darkButton = css`
  ${(props) => outlineButton(props.theme.colors.primary)}
`;

const secondaryButton = css`
  ${(props) => outlineButton(props.theme.colors.dark)};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.paragraphOnPrimary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.paragraphOnPrimary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const allWhiteButton = css`
  ${(props) => outlineButton(props.theme.colors.light)};
  background-color: ${(props) => props.theme.colors.light};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    border-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.paragraphOnPrimary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.paragraphOnPrimary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const whiteOnPrimary = css`
  ${(props) => outlineButton(props.theme.colors.light)};
  background-color: ${(props) => props.theme.colors.light};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.light};
    border-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.light};
    border-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const coloredButton = (color: ButtonProps['color']) => css`
  ${(props) => outlineButton(color ? props.theme.colors[color] : props.theme.colors.primary)}
`;

const getButtonVariant = (variant: ButtonProps['variant'], color: ButtonProps['color'], props: ButtonProps) => {
  switch (variant) {
    case 'dark':
      return darkButton;
    case 'colored':
      return coloredButton(color);
    case 'secondary':
      return secondaryButton;
    case 'allWhite':
      return allWhiteButton;
    case 'whiteOnPrimary':
      return whiteOnPrimary;
    default:
      return defaultButton(color, props);
  }
};

const buttonStyles = css<ButtonProps>`
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Ilisarniq GTG', 'Helvetica Neue', 'Arial', 'sans-serif';
  text-decoration: none;
  letter-spacing: 0.5px;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  outline: 0;
  padding: 0 ${(props) => props.theme.spacing.sp24};
  ${(props) => (props.fullWidth ? 'width: 100%' : '')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : 'font-size: 16px;')}

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  ${(props) => getButtonVariant(props.variant, props.color, props)};
  ${helpers};
`;

export default {
  Small: styled.button`
    ${buttonStyles};
    height: 32px;
  `,
  Medium: styled.button`
    ${buttonStyles};
    height: 40px;
  `,
  Large: styled.button`
    ${buttonStyles};
    height: 48px;
  `,
  xLarge: styled.button`
    ${buttonStyles};
    height: 56px;
  `,
  xxLarge: styled.button`
    ${buttonStyles};
    height: 68px;
  `,
  LinkMedium: styled.a`
    ${buttonStyles};
    height: 40px;
    line-height: 40px;
  `
};
