/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLError } from 'graphql';
import { ServerError, ServerParseError } from '@apollo/client';

export const getLocaleIdFromFetchError = async (error?: Error | any) => {
  // If we have a graphql error
  // if (error?.data?.error?.graphQLErrors || error?.data?.error?.networkError) {
  //   // Use existing parser
  //   return getLocaleIdFromGraphqlError(error?.data?.error?.graphQLErrors, error?.data?.error?.networkError);
  // } else
  if (error?.data?.code) {
    return `serverErrors.${error.data.code}`;
  } else if (error?.data?.name) {
    return `serverErrors.${error.data.name}`;
  } else if (error?.data?.message) {
    return `Not handled: ${error.data.message}`;
  } else if (error?.data?.extensions?.code) {
    return `serverErrors.${error.data.extensions.code}`;
  } else if (error?.data?.error?.code) {
    return `serverErrors.${error.data.error.code}`;
  } else if (error?.data?.error?.name) {
    return `serverErrors.${error.data.error.name}`;
  } else if (error?.data?.error?.message) {
    return `Not handled: ${error.data.error.message}`;
  } else if (error?.response?.text) {
    // If there's the "text" function(from fetch)
    // Try to call the function
    const message = (await error.response.text()) as string | undefined;

    // If the message is unauthorized
    if (message && message.toLowerCase() === 'unauthorized') {
      return 'serverErrors.unauthorized';
    } else if (message && message.toUpperCase().includes('ECONNREFUSED')) {
      // If there's no internet
      return 'serverErrors.connectionRefused';
    } else {
      // If the error is not handled yet
      return message ? `Not handled: ${message}` : 'serverErrors.unknownError';
    }
  } else if (error?.message && error.message.toLowerCase() === 'failed to fetch') {
    // If there's no internet
    return 'serverErrors.connectionRefused';
  } else {
    // If the error is not handled yet
    return error?.message ? `Not handled: ${error.message}` : 'serverErrors.unknownError';
  }
};

const expectedErrorNames = ['Error', 'TypeError'];

export const getLocaleIdFromGraphqlError = (
  graphQLErrors?: readonly GraphQLError[] | null,
  networkError?: Error | ServerError | ServerParseError | null
) => {
  if (
    networkError?.message === 'Failed to fetch' ||
    (networkError as any)?.code === 'ECONNREFUSED' ||
    networkError?.message?.toUpperCase().includes('ECONNREFUSED')
  ) {
    // If the internet is down
    return 'serverErrors.connectionRefused';
  } else if (networkError?.message) {
    // If there's a message in the network error and it's not one that we handled until this point
    return `Not handled: ${networkError.message}`;
  } else if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].extensions?.code === 'FORBIDDEN') {
    // If the error was caused by user being unauthorized
    // https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
    return 'serverErrors.unauthorized';
  } else if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors.some((x) => x.extensions?.custom)) {
    // Get the custom code
    const code = (graphQLErrors.find((x) => x.extensions?.custom) as GraphQLError).extensions?.code as string;
    return `serverErrors.${code}`;
  } else if (
    graphQLErrors &&
    graphQLErrors.length > 0 &&
    graphQLErrors.some(
      (x) =>
        x.extensions?.name && expectedErrorNames.some((expectedErrorName) => x.extensions?.name === expectedErrorName)
    )
  ) {
    // If we have a custom graphql error, we'll have the "extensions.name" property set to one of our expectedErrorNames array values
    // TODO: Also parse yup fields validation errors (with "extensions.name" as ValidationError). (They wouldn't even happen in the frontend since graphql validates the field before the backend does, so it's not needed for now)

    // Get the error
    const errorName = (graphQLErrors.find(
      (x) =>
        x.extensions?.name && expectedErrorNames.some((expectedErrorName) => x.extensions?.name === expectedErrorName)
    ) as GraphQLError).extensions?.name as string;

    // Return the locale with the error name appended to it (this should cover most cases)
    return `serverErrors.${errorName}`;
  } else if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].message) {
    // If there's a message in the graphql error and it's not one that we handled until this point
    return `Not handled: ${graphQLErrors[0].message}`;
  } else {
    // Unknown error
    return 'serverErrors.unknownError';
  }
};

export const getMessageFromGraphqlError = (graphQLErrors?: readonly GraphQLError[] | null) => {
  console.log('getMessageFromGraphqlError', graphQLErrors);
  if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors.some((x) => x.message)) {
    // Get the message
    const message = (graphQLErrors.find((x) => x.message) as GraphQLError).message;
    return message;
  }
  return undefined;
};
